export default {
  namespaced: true,

  state: {
    token: null,
    expiredAt: null,
    finop: null,
    fromId: null,
    iOpStatus: null,
    idu: null,
    parrainId: null,
    ueId: null,
    uid: null,
    visitId: null,
    oTagInfo: null,
  },

  actions: {
    setToken(context, payload) {
      context.commit('SET_TOKEN_DATA', payload);
    },

    removeToken(context) {
      context.commit('REMOVE_TOKEN_DATA');
    },
  },

  mutations: {
    SET_TOKEN_DATA(state, payload) {
      console.log('payload: ', payload);
      state.token = payload.data.token;
      state.expiredAt = payload.data.expiredAt;
      state.finop = payload.data.finop;
      state.fromId = payload.data.fromId;
      state.iOpStatus = payload.data.iOpStatus;
      state.idu = payload.data.idu;
      state.parrainId = payload.data.parrainId;
      state.ueId = payload.data.ueId;
      state.uid = payload.data.uid;
      state.oTagInfo = payload.data.oTagInfo;
      if (payload.data.visitId || payload.data.iVisitId) {
        state.visitId = payload.data.visitId || payload.data.iVisitId;
      }
    },

    REMOVE_TOKEN_DATA(state) {
      state.token = null;
      state.expiredAt = null;
      state.finop = null;
      state.fromId = null;
      state.iOpStatus = null;
      state.idu = null;
      state.parrainId = null;
      state.ueId = null;
      state.uid = null;
      state.visitId = null;
      state.oTagInfo = null;
    },
  },

  getters: {
    token: state => state.token,
    expiredAt: state => state.expiredAt,
    opEnded: state => state.finop,
    fromId: state => state.fromId,
    opStatus: state => state.iOpStatus,
    isRunning: state => state.iOpStatus === 1,
    isEnded: state => state.iOpStatus === 2,
    idu: state => state.idu,
    parrainId: state => state.parrainId,
    ueId: state => state.ueId,
    uid: state => state.uid,
    visitId: state => state.visitId,
    oTagInfo: state => state.oTagInfo,
    oTagInfoGaid: state => state.oTagInfo && state.oTagInfo.gaid ? state.oTagInfo.gaid : null,
    oTagInfoIdc: state => state.oTagInfo && state.oTagInfo.idc ? state.oTagInfo.idc : null,
    oTagInfoTagid: state => state.oTagInfo && state.oTagInfo.tagid ? state.oTagInfo.tagid : null,
  },
};
