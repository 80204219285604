<!-- COOKIES BANNER -->
<template>
  <div
    v-if="false && (cookiesBannerStatus && $route.name === 'register')"
    class="fixed z-10 flex flex-row items-center justify-center w-full p-2 text-center bg-gray"
    data-cy="cookie-banner"
    @click="hideCookiesBanner"
  >
    <dynamic
      name="cookie-banner"
      :template="$t('cookies.banner')"
      :data="{ updateModalConfig, hideCookiesBanner }"
    ></dynamic>
    <a data-cy="close-cookie-banner" @click="hideCookiesBanner" class="text-xl cursor-pointer close-btn">&times;</a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CookieBanner',

  data () {
    return {
      cookiesBannerStatus: true
    }
  },

  computed: mapGetters('config', ['appConfig']),

  methods: {
    ...mapActions(['updateModalConfig']),

    hideCookiesBanner () {
      this.$cookie.set(`${this.appConfig.baseName}CNIL`, true)
      this.cookiesBannerStatus = false
    },
  },

  mounted () {
    this.cookiesBannerStatus = !this.$cookie.get(`${this.appConfig.baseName}CNIL`)
  }
}
</script>

<style lang="scss" scoped>
</style>
