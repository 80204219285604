<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'MainPage',

  computed: {
    fromId() {
      if (!isNaN(this.$route.query.idfrom)) {
        return parseInt(this.$route.query.idfrom);
      }

      if (this.$store.getters['user/stateParams'] && this.$store.getters['user/stateParams'].idfrom) {
        return parseInt(this.$store.getters['user/stateParams']);
      }

      if (
        this.$store.getters['config/activeDecliConfig'].idfroms &&
        this.$store.getters['config/activeDecliConfig'].idfroms.untagged
      ) {
        return parseInt(this.$store.getters['config/activeDecliConfig'].idfroms.untagged);
      }

      return 0;
    },
  },

  watch: {
    $route: 'onRouteChange',
  },

  created() {
    
    this.$requestAPI.addEventListener('unauthorized', (event) => {
      event.detail.response.json().then(data => {
        const matchingRoute = this.$store.getters['config/activeDecliRoutes'].find(
          (route) => route.meta && route.meta.pageId === data.data[0],
        );

        if (matchingRoute) {
          this.$router.push({
            name: matchingRoute.name,
            reload: true
          })
            .catch(console.log);
        } else {
          this.$router.push({
            name: 'register',
            reload: true
          })
            .catch(console.log);
        }
      });
    });

    this.$requestAPI.addEventListener('not-found', (event) => {});

    // this.$requestAPI.addEventListener('token-expired', (event) => {});
    this.$requestAPI.addEventListener('token-expired', () => {
      this.$requestAPI.getSessionToken({
        res: `${screen.width}x${screen.height}`,
        fromId: this.fromId,
        urlParams: this.$route.query,
        ueid: this.$route.query.idue,
        parrainId: this.$route.query.idup,
        referrer: document.referrer,
      });
    });


    this.$requestAPI.addEventListener('service-unavailable', (event) => {});

    this.$requestAPI.addEventListener('token-regenerated', (event) => {
      const token = event.detail.response.headers.get('x-auth-token');
      this.$store.dispatch('global/setToken', { 
        data: event.detail.data.data[0], 
        token
      });
      this.$requestAPI.setSessionToken(token);
    });

    this.$requestAPI.addEventListener('session-current', (event) => {
      this.$store.dispatch('user/updateUser', event.detail.data[0]);
    });

    this.$requestAPI.addEventListener('session-current-from-auto-register', (event) => {
      this.$store.dispatch('user/updateUser', event.detail.data[0]);
      this.$store.dispatch('user/setAutoRegister', true);
    });

    this.$requestAPI.addEventListener('registered', (event) => {
      this.$store.dispatch('user/setRegisterData', event.detail.data[0]);
    });

    this.$requestAPI.addEventListener('relogged', (event) => {} );

    // TODO: view data content and make sure to store it in vuex.
    this.$requestAPI.addEventListener('registered-or-relogged', (event) => {
      console.log('TODO: registered-or-relogged event-emitter from Main.vue', event.detail);
    });

    this.$requestAPI.addEventListener('send-relog-email', (event) => {});
    
    this.$requestAPI.addEventListener('already-registered', (event) => {});
    
    this.$requestAPI.addEventListener('email-suggestion', (event) => {});

    this.$requestAPI.addEventListener('dedup-email-checked', (event) => {});

    this.$requestAPI.addEventListener('send-captcha2-token', (event) => {});

    this.$requestAPI.addEventListener('qualif-register-sent', (event) => {});

    this.$requestAPI.addEventListener('tracked-page-click', (event) => {});

    this.$requestAPI.addEventListener('sent-virals', (event) => {});

    this.$requestAPI.addEventListener('summary-viral', (event) => {});

    this.$requestAPI.addEventListener('offline', (event) => console.log('You are offline'));

    this.$requestAPI.addEventListener('server-error', (event) => {});

    // Retry will be insert if token expired
    // - retry stop the fetch
    // - after retry the fetch is resend
    this.$requestAPI.addRetry('token-expired', (response) => {
      if (this.$store.getters['user/uid']) {
        return this.$requestAPI.relog(this.$store.getters['user/uid'])
      } else {
        return this.$requestAPI.getSessionToken({
          res: `${screen.width}x${screen.height}`,
          fromId: this.fromId,
          urlParams: this.$route.query,
          ueid: this.$route.query.idue,
          parrainId: this.$route.query.idup,
          referrer: document.referrer,
        })
      }
    })

    // Add sync calback
    this.$requestAPI.addHook('relogged', (response) => {
      this.$requestAPI.setSessionToken(response.data.data[0]._id)
      return Promise.all([
        this.$store.dispatch('global/setToken', response.data.data[0]),
        this.$store.dispatch('user/setRelog', true)
      ])
    });
  },

  methods: {
    onRouteChange(to, from) {
      window.scrollTo(0, 0);

      if (to.meta && to.meta.requiresAuth) {
        this.$requestAPI.getSessionCurrent().catch(console.log);
      }

      if (to && to.meta) {
        if (to.meta.pageId) {
          this.$mmTro.addPageId(to.meta.pageId, to.query);
        }
        if (to.name !== 'OptinisationPage') {
          if (!to.meta.requiresAuth && !to.meta.afterEnd) {
            this.$mmTro.addFormPage(to.query);
          } else if (!to.meta.conversionPage) {
            this.$mmTro.addGenericPage(to.query);
          }
        }
      } else {
        this.$mmTro.addToQueue();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scope>
</style>
