export default {
  siteTitle: 'Ma French Bank - Newsletter',
  prodUrl: 'ma-french-bank-newsletter.numberly.in',
  facebook: {
    active: false,
    fbSdkLang: 'fr_FR',
    appIds: {
      staging: 'xxx',
      prod: 'xxx'
    }
  },
  twitter: {
    active: false
  },
  iframe: {
    active: true,
    urlToRedirToProd: 'https://www.mafrenchbank.fr/inscription-newsletter.html',
    urlToRedirToStaging: 'https://sitevitrine.b.mafrenchbank.fr/information/inscription-newsletter.html',
  },
  optinisation: {
    active: false
  },
  idfroms: {
    untagged: 0,
    facebook: 2,
    twitter: 3,
    whatsapp: 4,
    messenger: 5,
    urlcopy: 6
  },
  linkNumbers: {
    facebook: 1,
    twitter: 2,
    urlcopy: 3,
    whatsapp: 4,
    messenger: 5
  }
}
