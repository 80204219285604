import Doorman from '@/router/doorman'

export default [
  {
    name: 'register',
    path: '',
    component: () => import('@/views/Register'),
    meta: {
      pageId: 1,
      autoPageView: true,
      requiresAuth: false,
      afterEnd: false,
      nextPage: 'confirm'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'confirm',
    path: 'confirmation',
    component: () => import('@/views/Diffusion'),
    meta: {
      pageId: 2,
      autoPageView: true,
      requiresAuth: false,
      afterEnd: false,
      conversionPage: true
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'end',
    path: 'fin',
    component: () => import('@/views/End'),
    meta: {
      pageId: 3,
      autoPageView: true,
      afterEnd: true,
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  }
]
