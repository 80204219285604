import appConfig from '@/declis';
import { RequestAPI } from '@/api/request-api';
import { getDecli, isProdEnv } from '@/helpers';

/**
 * Interact with the API based on the active decli. Contains most used API
 * calls in methods like `getSessionToken`, `getSessionCurrent`.
 * 
 * ```js
 * // Retrieve the instance from the singleton (can be placed at the top of your file)
 * const requestAPI = new CurrentRequestAPI();
 * // Use methods from the `RequestAPI` class
 * requestAPI.getSessionToken({ ...data });
 * ```
 */
export default class CurrentRequestAPI extends RequestAPI {
  /** @type {import('./request-api').RequestAPI} */
  static INSTANCE;

  constructor() {
    if (RequestAPI.INSTANCE) {
      return RequestAPI.INSTANCE;
    }

    let host = document.location.host;

    super(
      `${document.location.protocol}//${host}/api`,
    );

    RequestAPI.INSTANCE = this;
  }
}
