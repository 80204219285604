import cookiesBanner from './html/cookies/banner.html'
import cookiesModalContent from './html/cookies/modal/content.html'
import desaboLink from './html/desabo/link.html'
import registerTitle from './html/register/title.html'
import footerRealisationAddress from './html/footer/realisation/address.html'
import optinisationSubtitle from './html/optinisation/subtitle.html'

export default {
  loading: "Loading",
  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },
  desabo: {
    title: "You have been unsubscribed successfully",
    link: desaboLink,
    resub: "You have been resubscribed"
  },
  register: {
    title: registerTitle,
    subtitle: "",
    already_registered: "Already registered ?",
    not_registered: "Not registered yet",
    not_mandatory: "Not mandatory",
    form: {
      gender: "Civility",
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      mobile: "Mobile number",
      zipcode: "Zipcode",
      city: "City",
      country: "Country",
      birthday: "Date of birth",
      submit: "Validate",
      terms : "xxxxxxxxxxxxxxx",
      errors: {
        birthday: {
          invalid: "Your date of birth is invalid.",
          too_young: "You must be {age} years old to participate."
        },
        email: {
          already_used: "This email address has already been registered."
        }
      }
    },
    relog: {
      description: "If you are registered, you will receive an email to reconnect.",
      resend: "I didn't receive the email",
      submit: "Send"
    }
  },
  diffusion: {
    title: "",
    subtitle: "",
    share: {
      email: "Email",
      facebook: "Facebook",
      twitter: "Twitter",
      whatsapp: "Whatsapp",
      messenger: "Messenger",
      link: "Link",
      summary: ""
    }
  },
  footer: {
    terms: "Terms",
    mentions: "Mentions",
    contact: {
      button: "Contact Us",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Privacy Policy",
    realisation: {
      button: "Copyright",
      address: footerRealisationAddress,
      email: "Email",
      contact: "Do not hesitate to contact us.",
      mailto: "katherin@numberly.com",
      numberly: "http://www.numberly.com"
    }
  },
  modals: {
    share_link: {
      title: "Share the link with your friends",
      subtitle: "by copying the URL",
      action: "COPY",
      success: "Link copied successfuly"
    },
    summary: {
      title: "Your invited friends",
      relance: "Resend",
      registered: "Registered",
      button: "See my registered friends"
    },
    share_email: {
      title: "Invite your friends",
      subtitle: "via their email address",
      email: "Email {index}",
      already_used: "The email address has already been used.",
      mentions: "The email addresses given here will alllow us to send invitations to your friends to also take part in the quiz. These emails will not be retained nor used after the game.",
      submit: "Send my invites",
      error: {
        no_email: "Enter at least one email",
        duplicate_email: "At least 2 of the emails you entered are identical.",
        own_email: "You cannot use your own email."
      },
      success: "The email has been sent.|The emails have been sent."
    }
  },
  optinisation: {
    title: "Thank you!",
    subtitle: optinisationSubtitle
  }
}