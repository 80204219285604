// IE11 polyfill for `fetch`.
import 'whatwg-fetch';

import './styles/tailwind.scss'
import './styles/base/index.scss'

import { iframeResizerContentWindow } from "iframe-resizer";
iframeResizerContentWindow;

import Vue from 'vue'
import Main from './Main.vue'
import store from './store'
import genRouter from './router'
import { sync } from 'vuex-router-sync'
const appConfig = require('@/declis')
import NCta from '@team-uep/n-cta'
import NModal from '@team-uep/n-modal'

// GA 
import VueAnalytics from 'vue-analytics'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

// FORMS

import { getDecli, isDebugMode, isProdEnv, isStagingEnv } from '@/helpers'// OP DATA

// OP DATA
import { version, name } from '../package.json'


// Add custom class for IE
if (navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Trident') > -1) {
  document.documentElement.setAttribute('data-useragent', 'IE');
}

// REMOVE CONSOLE LOG IN PRODUCTION
if (isProdEnv() && !isDebugMode()) {
  console.log = function () { }
}

// VUE CONFIG
Vue.config.productionTip = false
Vue.config.env = process.env.NODE_ENV // Env: to detect local vs build

// ACTIVATE PLUGINS
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)



// GLOBALS
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import Cookies from '@/components/Cookies.vue'
import DynamicTemplateCompile from '@/components/DynamicTemplateCompile'
import SocialButton from '@/components/SocialButton'
Vue.component('SiteHeader', SiteHeader)
Vue.component('SiteFooter', SiteFooter)
Vue.component('Cookies', Cookies)
Vue.component('Dynamic', DynamicTemplateCompile)
Vue.component('SocialButton', SocialButton)
Vue.component('NCta', NCta)
Vue.component('NModal', NModal)

// GLOBAL MIXINS
import trackedClickMixin from '@/mixins/trackedClick';

Vue.mixin(trackedClickMixin);

// PLUGINS
import {
  fbSdkInit,
  twitterSdkInit,
  mmTro,
  requestAPI,
} from './plugins'

import i18n from './i18n'

// SET ACTIVE DECLI
appConfig.activeDecli = getDecli(appConfig.declis)

// VeeValidate
import fr from 'vee-validate/dist/locale/fr.json'
import { email, required, min } from 'vee-validate/dist/rules'
import { ValidationObserver, localize, extend } from 'vee-validate'

const rules = {
  'email': email,
  'required': required,
  'min': min,
  'already_registered': {
    validate: (value, [state]) => {
      return state == 'false' ? true : state == 'true' ? false : true 
    },
    message : 'L\'adresse email renseignée est déjà utilisée.',
  },
  'requiredCheckbox': {
    validate: (value) => {
      return value > 0
    },
    message: (field) => {
      return `Le champs est obligatoire.`
    }
  },
  'ageMin': {
    params: ['ageMin'],
    validate: (value, { ageMin }) => {
      var userDate, splitValue
      if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
        splitValue = value.split('/')
        userDate = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
      } else {
        splitValue = value.split('-')
        userDate = new Date(splitValue[0], parseInt(splitValue[1] - 1), splitValue[2])
      }
      var newYear = userDate.getFullYear() + parseInt(ageMin)
      var tempDate = new Date(newYear, userDate.getMonth(), userDate.getDate())
      return tempDate <= new Date()
    },
    message: "L'inscription à notre newsletter est réservée au plus de {ageMin} ans."
  },
  'ageMax': {
    params: ['ageMax'],
    validate: (value, { ageMax }) => {
      var userDate, splitValue
      if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
        splitValue = value.split('/')
        userDate = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
      } else {
        splitValue = value.split('-')
        userDate = new Date(splitValue[0], parseInt(splitValue[1] - 1), splitValue[2])
      }
      var date = new Date()
      return userDate.getFullYear() >= date.getFullYear() - parseInt(ageMax)
    },
    message:
      "L'âge maximum pour participer est {ageMax} ans."
  },
  'validDate': {
    validate: (value) => {
      var splitValue, date
      if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
        splitValue = value.split('/')
        if (splitValue[1] - 1 > 11) {
          return false
        } else if (splitValue[0] > 31) {
          return false
        } else {
          date = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
          return date instanceof Date && !isNaN(date) && date.getFullYear() < 2100 && date.getFullYear() > 1900
        }
      } else {
        splitValue = value.split('-')
        if (splitValue[1] - 1 > 11) {
          return false
        } else if (splitValue[2] > 31) {
          return false
        } else {
          date = new Date(splitValue[0], parseInt(splitValue[1] - 1), splitValue[2])
          return date instanceof Date && !isNaN(date) && date.getFullYear() < 2100 && date.getFullYear() > 1900
        }
      }
    },
    message: (field) => {
      return `Le champ ${field} est invalide.`
    }
  },
  'mobileType': {
    validate: (value) => {
      return value.match(/^((\+)33|0)[1-9](\d{2}){4}$/)
    },
    message: (field) => {
      return `Le champ ${field} est invalide.`
    }
  },
  'nameType': {
    validate: (value) => {
      return value.match(/^([\sa-zàáâäçèéêëìíîïñòóôöùúûüA-ZÀÁÂÄÇÈÉÊËÌÍÎÏÑÒÓÔÖÙÚÛÜ.'-]+(( |')[a-zàáâäçèéêëìíîïñòóôöùúûüA-ZÀÁÂÄÇÈÉÊËÌÍÎÏÑÒÓÔÖÙÚÛÜ]+)*)+([-]([a-zàáâäçèéêëìíîïñòóôöùúûüA-ZÀÁÂÄÇÈÉÊËÌÍÎÏÑÒÓÔÖÙÚÛÜ]+(( |')[a-zàáâäçèéêëìíîïñòóôöùúûüA-ZÀÁÂÄÇÈÉÊËÌÍÎÏÑÒÓÔÖÙÚÛÜ]+)*)+)*$/)
    },
    message: (field) => {
      return `Veuillez renseigner un format de ${field} correct.`
    }
  }
}

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.component('ValidationObserver', ValidationObserver)
localize('fr', fr)

if (isDebugMode()) {
  console.log(`\n🐞 DEBUG MODE\n\n - Logs: enabled\n - MMtro: disabled\n - Sentry: disabled\n - Doorman: disabled\n\n`)
}

// Log current OP version
console.log('Client version', version)

const activeDecli = require(`@/declis/${appConfig.activeDecli.name}/index.js`).default

// SENTRY
// vuepress@sentry-init
if (appConfig.sentryKey && !isDebugMode() && (isProdEnv() || isStagingEnv())) {
  let environment = 'undefined'
  if (isProdEnv()) {
    environment = 'production'
  } else if (isStagingEnv()) {
    environment = 'staging'
  } else {
    environment = 'development'
  }
  
  Sentry.init({
    environment,
    dsn: appConfig.sentryKey,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    release: name + '@' + version
  });
}
// vuepress@sentry-init

// ROUTER
const syncRouter = genRouter(activeDecli.routes) // Create router
sync(store, syncRouter) // Sync router & store

// INIT API OP PLUGIN
Vue.use(appConfig)
Vue.use(requestAPI);

Vue.$requestAPI.setSessionToken(store.getters['global/token'])

// CHECK WRAPPER
store.dispatch('checkIfWebView')

// INIT FB SDK PLUGIN WITH OPTION (for SDK language)
if (activeDecli.config.facebook.active) {
  Vue.use(fbSdkInit, {
    appIds: activeDecli.config.facebook.appIds,
    lang: activeDecli.config.facebook.fbSdkLang
  })
}

// INIT TWITTER SDK
if (activeDecli.config.twitter.active) {
  Vue.use(twitterSdkInit)
}

// GA
// if (store.getters.session.oTagInfo && store.getters.session.oTagInfo.gaid) {
if (store.getters['global/oTagInfoGaid']) {
  Vue.use(VueAnalytics, {
    id: store.getters['global/oTagInfoGaid'],
    syncRouter,
  });
}

// MMTRO
Vue.use(mmTro)

// TITLE META
document.title = activeDecli.config.siteTitle

// SAVE APPCONFIG IN A STORE
store.dispatch('config/updateAppConfig', appConfig)

// STORE DECLI CONTENT IN STORE
store.dispatch('config/updateActiveDecli', activeDecli)

/* eslint-disable no-new */
new Vue({
  store,
  router: syncRouter,
  i18n,
  render: h => h(Main)
}).$mount('#main')
