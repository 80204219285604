import cookiesBanner from './html/cookies/banner.html'
import cookiesModalContent from './html/cookies/modal/content.html'
import desaboLink from './html/desabo/link.html'
import registerTitle from './html/register/title.html'
import registerInstruction from './html/register/instruction.html'
import diffusionTitle from './html/diffusion/title.html'
import diffusionSubtitle from './html/diffusion/subtitle.html'
import endSummary from './html/end/summary.html'
import footerRealisationAddress from './html/footer/realisation/address.html'
import footerFooterTermsModal from './html/footer/footer_terms_modal.html'
import footerFooterChartefbModal from './html/footer/footer_chartefb_modal.html'
import footerFooterRealisationModal1000mercis from './html/footer/footer_realisation_modal_1000mercis.html'
import footerFooterRealisationModalNumberly from './html/footer/footer_realisation_modal_numberly.html'
import footerFooterMentions from './html/footer/footer_mentions.html'
import footerFooterMentions2 from './html/footer/footer_mentions2.html'
import footerMentions from './html/footer/mentions.html'
import modalsShareLinkTitle from './html/modals/share_link/title.html'
import optinisationSubtitle from './html/optinisation/subtitle.html'

export default {
  loading: "Chargement",
  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },
  desabo: {
    title: "Votre désinscription a bien été prise en compte.",
    button: "Je me réinscris",
    link: desaboLink,
    resub: "Vous êtes bien réinscrit"
  },
  register: {
    title: registerTitle,
    subtitle: "",
    instruction: registerInstruction,
    already_registered: "Déjà inscrit ?",
    not_registered: "Pas encore inscrit",
    not_mandatory: "Pas obligatoire",
    form: {
      gender: "Civilité",
      first_name: "Prénom",
      last_name: "Nom",
      email: "Email",
      mobile: "Numéro de mobile",
      zipcode: "Code postal",
      city: "Ville",
      country: "Pays",
      birthday: "Date de naissance",
      iOptin_2_label: "J'accepte de recevoir par email et/ou sms des offres commerciales de Ma French Bank et des sociétés du groupe La Poste",
      terms: "J’accepte le règlement xxxxxxxxxxxxxxxxxx",
      submit: "Valider",
      errors: {
        birthday: {
          invalid: "Votre date de naissance est incorrecte.",
          too_young: "Vous devez avoir plus de {age} ans pour participer."
        },
        email: {
          invalid: "Le champ Email doit être une adresse e‑mail valide.",
          already_used: "L'adresse email renseignée est déjà utilisée."
        }
      }
    },
    relog: {
      description: "Si vous êtes inscrit(e), vous allez recevoir un email pour vous reconnecter.",
      resend: "Je n'ai pas reçu l'email",
      submit: "Envoyer"
    }
  },
  diffusion: {
    title: diffusionTitle,
    subtitle: diffusionSubtitle,
    redirection: "Découvrir nos offres",
    share: {
      email: "Email",
      facebook: "Facebook",
      twitter: "Twitter",
      twitter_text: "twitter text",
      whatsapp: "Whatsapp",
      whatsapp_text: "whatsapp text",
      messenger: "Messenger",
      link: "Lien",
      summary: endSummary
    }
  },
  end: {
    title: "Merci pour votre participation !",
    subtitle: "L’opération XXXX est désormais terminée. Le gagnant du tirage au sort sera contacté prochainement par email.",
    redirection: "Découvrir nos offres"
  },
  footer: {
    terms: "Règlement",
    mentions: footerMentions, 
    contact: {
      button: "Contact",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Charte de confidentialité",
    realisation: {
      button: "Réalisation",
      address: footerRealisationAddress,
      email: "Email",
      contact: "N'hésitez pas à nous contacter",
      mailto: "juliette@numberly.com",
      numberly: "http://www.numberly.com/fr"
    },
    footer_terms_btnText: "Règlement",
    footer_terms_modal: footerFooterTermsModal,
    footer_contact_btnText: "Contact",
    footer_contact_email: "contact@lequizduchef-bbq-XXXXXXX.fr",
    footer_contact_subject: "XXXXXXX -  XXXXXXXXXX",
    footer_chartefb_client: "XXXXXXX",
    footer_chartefb_btnText: "Charte de confidentialité",
    footer_chartefb_modal: footerFooterChartefbModal,
    footer_realisation_btnText: "Réalisation",
    footer_realisation_modal_1000mercis: footerFooterRealisationModal1000mercis,
    footer_realisation_modal_numberly: footerFooterRealisationModalNumberly,
    footer_mentions: footerFooterMentions,
    footer_mentions2: footerFooterMentions2
  },
  modals: {
    share_link: {
      title: modalsShareLinkTitle,
      subtitle: "en copiant l'URL",
      action: "COPIER",
      success: "Le lien a bien été copié"
    },
    summary: {
      title: "Vos amis invités",
      relance: "Relancer",
      registered: "Inscrit"
    },
    share_email: {
      title: "Invitez vos amis",
      email: "Email {index}",
      subtitle: "via leur adresse email",
      already_used: "L'adresse email renseignée est déjà utilisée.",
      mentions: "Les adresses email que vous indiquez ici nous permettront de transmettre à vos amis, vos invitations à venir jouer à ce jeu. Ces adresses ne seront ni conservées, ni réutilisées à l’issue du jeu.",
      submit: "J'envoie mes invitations",
      error: {
        no_email: "Veuilllez saisir au moins un email",
        duplicate_email: "Au moins 2 des emails que vous avez saisis sont identiques.",
        own_email: "Vous ne pouvez pas saisir votre propre email."
      },
      success: "L'email a bien été envoyé.|Les emails ont bien été envoyés."
    }
  },
  optinisation: {
    title: "Merci!",
    subtitle: optinisationSubtitle
  }
}
