<template>
  <div>
    <div
      class="flex flex-col flex-wrap items-center justify-center m-auto lg:flex-row lg:w-8/12 container"
    >
      <!-- TERMS -->
      <template v-if="$te('footer.terms')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="terms-open-cta"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })"
          >{{$t('footer.terms')}}</a>
          <span class="dot">&nbsp;•&nbsp;</span>
        </div>
      </template>
      <!-- CONTACT -->
      <template v-if="$te('footer.contact.button')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="contact-cta"
            :href="`mailto:${$t('footer.contact.email')}?subject=${$t('footer.contact.subject')}`"
            target="_blank"
          >{{$t('footer.contact.button')}}</a>
          <span class="dot">&nbsp;•&nbsp;</span>
        </div>
      </template>
      <!-- CHARTE FB -->
      <template v-if="$te('footer.charteFb')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="confidentialite-cta" 
            @click="updateModalConfig({ isOpen: true, modalComponent: 'CharteFbModal' })"
          >{{$t("footer.charteFb")}}</a>
          <span class="dot">&nbsp;•&nbsp;</span>
        </div>
      </template>
      <!-- REALISATION -->
      <template v-if="$te('footer.realisation.button')">
        <div>
          <a
            data-cy="realisation-cta" 
            class="mr-0 cursor-pointer"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'RealisationModal', modalClass: 'realisation-modal' })"
          >{{$t('footer.realisation.button')}}</a>
        </div>
      </template>
    </div>
    <!-- MENTIONS -->
    <div class="textFooter">
      <dynamic :template="$t('footer.mentions')" :data="{ updateModalConfig }"></dynamic>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SiteFooter',
  methods: mapActions(['updateModalConfig'])
}
</script>

<style lang="scss" scoped>
.dot {
  @apply hidden;
  @screen lg {
    @apply inline mr-6;
  }
}
a {
  @screen lg {
    @apply mr-6;
  }
}
.container {
  margin-top: 2.5rem;
  @media (max-width: 1024px) {
    margin-top: 5rem;
  }
}
.textFooter {
  max-width: 1280px;
}
.textFooter ::v-deep {
  margin: auto;
  text-align: center;
  margin: 30px auto;
  padding: 0px 30px;
}
</style>
