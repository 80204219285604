import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import { getDecli } from '@/helpers'
import appConfig from '@/declis'
appConfig.activeDecli = getDecli(appConfig.declis)
const localeName = appConfig.activeDecli.name

function loadLocaleMessages () {
  const messages = {}
  messages[localeName] = require(`@/declis/${localeName}/index.js`).default.translations
  return messages
}

export default new VueI18n({
  locale: localeName || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
